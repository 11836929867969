/* Add these styles to the existing index.css file */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ai-task-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-task-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.ai-task-popup-workflow {
  flex: 1;
  overflow: hidden;
}

.ai-task-popup-editor {
  flex: 1;
  overflow: hidden;
}

.ai-task-popup-actions {
  margin-top: 20px;
}