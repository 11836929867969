.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* input */

.input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input:focus {
  outline: none;
  border-color: #FF5D11;
}

/* icp-generation */

.icp-generation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  
}

.icp-generation-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
}

.input-group {
  display: flex;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin-bottom: 1rem;
}

.icp-generation-input {
  flex: 1;
  border-radius: 4px 0 0 4px;
}

.icp-generation-button {
  padding: 10px 20px;
  background-color: #FF5D11;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.icp-generation-button:hover {
  background-color: #DD4700;
}

.output-container {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
}

.icp-generation-output {
  width: 100%;
  max-width: 800px;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icp-generation-output-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.icp-generation-output-title {
  font-size: 1.25rem;
  color: #333;
}

.icp-generation-output-header h3 {
  margin: 0;
}

.icp-generation-copy-button {
  background: none;
  border: none;
  cursor: pointer;
}

.icp-generation-copy-icon {
  height: 24px;
  width: 24px;
}

.pre-container {
  position: relative; /* Establishes a positioning context for absolute positioning inside */
  background-color: #eef;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

.pre-container pre {
  margin: 0; /* Removes default margin */
}

.pre-container svg {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  cursor: pointer; /* Makes it clear the icon is clickable */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.feature-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.feature-selection label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}


.task-component {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 100%;
  overflow: auto; /* Add this line to enable scrolling within the component */
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
}

.task-component h3 {
  margin-top: 0;
  flex-grow: 1; /* Allows the title to take up any available space */
}

.task-component button {
  padding: 10px 20px;
  background-color: #FF5D11;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.task-component button:hover {
  background-color: #DD4700;
}

.task-component pre {
  background-color: #eef;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap; /* Add this line to wrap the text */
  word-wrap: break-word; /* Add this line to break long words */
  overflow: auto; /* Add this line to enable scrolling within the pre element */
  
}

/* custom blocknote styles */


bn-container {
  border: #000000;
  border-radius: 10px;
  border-width: 5px;
}
.ProseMirror.bn-editor.bn-default-styles {
  min-height: 100vh;
}



/* homepage */

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.button-container {
  display: flex;
  gap: 20px;
}

.button {
  padding: 10px 20px;
  background-color: #FF5D11;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #DD4700;
}

/* vertical navbar */ 

.vertical-navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  left: 0;
  width: 13%;
  height: 100vh;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);

}

.navbar-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.navbar-logo img {
  max-width: 90%;
}

.navbar-button {
  padding: 10px 20px;
  background-color: #ff5b0f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  width: 50%;
  text-align: center;
}

.navbar-button:hover {
  background-color: #FF5D11;
}

/* editor footer */

.editor-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.editor-footer-button {
  padding: 10px 20px;
  background-color: #FF5D11;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.editor-footer-button:hover {
  background-color: #FF5D11;
}

/* login */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.auth-ui {
  width: 100%; /* Adjust width as needed */
  max-width: 360px; /* Provides a maximum width for the Auth UI */
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: white;
}

