.workflow-manager {
    display: flex;
    height: 100%;
  }
  
  .workflow-manager-flow {
    flex: 1;
    position: relative;
  }
  
  .workflow-manager-editor {
    flex: 1;
  }
  
  .add-task-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #ff5b0f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }