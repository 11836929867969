.task-block-node {
    background: #fff;
    border: 1px solid #1a192b;
    border-radius: 4px;
    padding: 10px;
    width: 200px;
    font-family: sans-serif;
    text-align: center;
  }
  
  .task-block-node.selected {
    border-color: #ff0071;
    box-shadow: 0 0 5px #ff0071;
  }
  
  .task-block-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .task-block-label {
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }
  
  .edit-icon {
    margin-left: 8px;
    cursor: pointer;
  }
  
  .task-block-snippet {
    font-size: 12px;
    color: #888;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  
  .react-flow__handle {
    background: #555;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  
  .react-flow__handle.react-flow__handle-top {
    top: -4px;
  }
  
  .react-flow__handle.react-flow__handle-bottom {
    bottom: -4px;
  }